import React, { Component } from "react";
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
import "./slick.css";
import "./slick-theme.css";
import  * as styles from "./testimonial.module.css"
import { green } from "color-name";
// import star from "../../../assets/images/icons/stars/stars.svg";


const  content = require("../../../content/reviews")

export default class Testimonial extends Component {
  render() {
    const settings = {
      dots: false,
      arrows: false,
      fade: true,
      infinite: true,
      speed: 1000,
      autoplay: true,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      // className: "testimonial",
    };
    return (
      <div className={styles.testContainer}>
        <Slider {...settings}>
          {content.reviews.map((review, index) => (
            <div  key={index} className={styles.textContainer}>
              <div className={styles.testInner}>
                {review.testimonial}
              </div>
              <div className={styles.reviewer}>
                <hr/>
                <div className={styles.nameContainer}>
                  <div className={styles.name}>
                    {review.name}<br/>
                    <i>{review.type}</i>
                  </div>
                  <div className={styles.stars}>
                  <img src={'/icons/star'+`${review.icon}`} alt="img" />
                  </div>
                </div>
              </div>

            </div>
          ))}
        </Slider>
      </div>
    );
  }
}
