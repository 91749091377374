import React from "react"
import sal from "sal.js"
import content from "../../content/about"
import * as styles from "./about.module.css"
import Process from "./sections/process.js"
import Testimonial from "./sections/testimonial.js"


export default function AboutPage() {
  React.useEffect(() => {
    sal()
  }, [])

  return (
    <div className={`${(styles.aboutContainer, styles.displayFlex)}`}>
      {/* First Part */}
      <div className={styles.part1}>
        <div
          data-sal="slide-up"
          data-sal-delay="150"
          data-sal-duration="350"
          data-sal-easing="ease-in"
          className={styles.part1Main}
        >
          <div className={styles.part1Header}>
            <div>{content.firstPart.main}</div>
          </div>
          <div className={styles.part1ImgHolder}>
            <div className={styles.firstImgHeight}>
              <img src="https://res.cloudinary.com/metrodesign/image/upload/v1646190565/metro-flooring/Kitchen/Green_Kitchen_av5laj.jpg"></img>
            </div>
          </div>
        </div>

        <div
          data-sal="slide-up"
          data-sal-delay="150"
          data-sal-duration="350"
          data-sal-easing="ease-in"
          className={`${styles.part1Description} ${styles.description}`}
        >
          {content.firstPart.description}
        </div>

        {/* Second Part  */}
      </div>
      <div className={styles.secondPart}>
        <div className={styles.secondPartImgContainer}>
          <img
            className={styles.secondPartImage}
            src={content.secondPart.image}
            alt=" "
            data-sal="slide-up"
            data-sal-delay="150"
            data-sal-duration="350"
            data-sal-easing="ease-in"
          />
        </div>

        <div className={styles.secondPartBox}>
          <div
            className={`${styles.secondPartBoxTitle} ${styles.title}`}
            data-sal="slide-up"
            data-sal-delay="150"
            data-sal-duration="350"
            data-sal-easing="ease-in"
          >
            <div>{content.secondPart.title}</div>
          </div>
          <div
            data-sal="slide-up"
            data-sal-delay="150"
            data-sal-duration="350"
            data-sal-easing="ease-in"
            className={`${styles.secondPartBoxMain} ${styles.main}`}
          >
            <div>{content.secondPart.main}</div>
            <div
              data-sal="slide-up"
              data-sal-delay="150"
              data-sal-duration="350"
              data-sal-easing="ease-in"
              className={styles.submain2}
            >
              <div>{content.secondPart.submain}</div>
            </div>
          </div>
          <div
            data-sal="slide-up"
            data-sal-delay="150"
            data-sal-duration="350"
            data-sal-easing="ease-in"
            className={`${styles.secondPartBoxDescription} ${styles.description}`}
          >
            <div>{content.secondPart.description}</div>
          </div>
        </div>
      </div>

      {/* Third Part */}

      <div className={styles.thirdPart}>
        <div
          className={`${styles.thirdPartTitle} ${styles.title}`}
          data-sal="slide-up"
          data-sal-delay="150"
          data-sal-duration="350"
          data-sal-easing="ease-in"
        >
          <div>
            <div>{content.thirdPart.title}</div>
          </div>
        </div>
        <div
          className={`${styles.thirdPartMain} ${styles.main}`}
          data-sal="slide-up"
          data-sal-delay="150"
          data-sal-duration="350"
          data-sal-easing="ease-in"
        >
          <div>
            <div>{content.thirdPart.main}</div>
          </div>
        </div>

        <div
          data-sal="slide-up"
          data-sal-delay="150"
          data-sal-duration="350"
          data-sal-easing="ease-in"
          className={`${styles.testimonial} ${styles.right}`}
        >
          <div className={styles.main}>
            <Testimonial />
          </div>
        </div>
      </div>

      <div className={`${styles.fourthPart} ${styles.displayFlex}`}>
        <section className={styles.fourthContainer}>
          <div className={styles.fourthHeader}>
            <div className={`${styles.fourthPartTitle} ${styles.title}`}>
              {content.fourthPart.title}
            </div>
            <div className={`${styles.fourthPartMain} ${styles.main}`}>
              {content.fourthPart.main}
            </div>
          </div>
          <Process />
        </section>
      </div>
      <div className={styles.contactContainer}>
        <div
          id="contact"
          className={`${styles.fifthPart} ${styles.displayFlex}`}
        >
          <div
            data-sal="slide-up"
            data-sal-delay="150"
            data-sal-duration="350"
            data-sal-easing="ease-in"
            className={styles.title}
          >
            {content.fifthPart.title}{" "}
          </div>
          {/* <div className={cn([styles.fifthPartMain} ${styles.main])}>
            {content.fifthPart.main}
          </div> */}
          <div
            className={`${styles.fifthPartDescription} ${styles.description}`}
            data-sal="slide-up"
            data-sal-delay="150"
            data-sal-duration="350"
            data-sal-easing="ease-in"
          >
            {content.fifthPart.description.address}
            <br />
            {content.fifthPart.description.location}
            <br />
            {content.fifthPart.description.phone}
            {/* <br />
            {content.fifthPart.description.email}
            <br /> */}
          </div>
        </div>
        <div className={styles.mapouter}>
          <iframe
            className={styles.gmap_canvas}
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3109.354202231467!2d-76.90958788467945!3d38.8014366602!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7bc704c1d9457%3A0xb07fa7e57ac8d0f6!2sMetro%20Flooring%20%26%20Interior%20Design!5e0!3m2!1sen!2sus!4v1646602261334!5m2!1sen!2sus"
          />
        </div>
      </div>
    </div>
  )
}
