import { brown } from "color-name";

export default {
  firstPart: {
    main: "The remodeling team you can recommend to your friends. Metro Flooring & Interior Design offers tailor-made solutions for the layout and decoration of any interior.",
    description:
      "Trust is the key component that makes Metro Flooring and Interior Design successfull. Sourounded by a team of talented craftsman we have been creating beautiful kitchen and bath remodels throguhout the DC Metropolitan area for over 20 years. From corner bathroom tiling to expanding kitchens we are passionate about reimagining spaces.",
  },
  secondPart: {
    title: "Leaving a lasting impression",
    image:
      "https://res.cloudinary.com/metrodesign/image/upload/c_scale,w_400/v1598193147/metro-flooring/Bathroom/Marble/Bathroom_Marble_4.jpg",
    main:
      '"People will forget what you said, people will forget what you did, but people will never forget how you made them feel."',
    submain:
    '- Maya Angelou',
    description:
      "To us, the final impression is just as important, if not more than, the first one. Thanks to our incredible clients and amazing team much of our business growth has come by word of mouth. Our approach is simply, to listen.  No one knows what you want out of your space more than you.  We are here to be your champion and leverage our relationships to ensure you get the best styles and products your space deserves. Each project is tailored to fit your lifestyle, and from the initial envisioning process we work with you to tease out your ideal vision.",
  },
  thirdPart: {
    title: "This is about us, but it's really about you!",
    main:
      "Renovating a home can be stressful, which is why we are committed to providing you with a great customer experience. With us handling everything from project management to material procurement, our aim is to leave you with a space that is better than you imagined.",
    description:
      "Renovation a home can be stressful, which is why we are committed to providing you with a great customer experience. With us handling everything from project management to material procurement, our aim is to leave your space better than you imagined. We treat every client’s needs uniquely and scheduling an appointment with us is the first step to making your space fascinating. ",
  },
  fourthPart: {
    title: "How It Works",
    main:
      "Our Design Process",
    firstImage:
      "https://res.cloudinary.com/metrodesign/image/upload/c_scale,w_600/v1598193173/metro-flooring/Kitchen/Wood/Wood_2_e3q55o.jpg",
    secondImage:
      "https://res.cloudinary.com/metrodesign/image/upload/c_scale,w_600/v1598193240/metro-flooring/Kitchen/Sunrise%20Kitchen/Sunrise_Kitchen_1_brgjft.jpg",
    thirdImage:
      "https://res.cloudinary.com/metrodesign/image/upload/c_scale,w_600/v1598193152/metro-flooring/Bathroom/Tile/Bathroom_Tile_3_hww8xj.jpg",
    fourthImage:
      "https://res.cloudinary.com/metrodesign/image/upload/c_scale,w_600/v1598193148/metro-flooring/Bathroom/Marble/Bathroom_Marble_3.jpg",
  },
  fifthPart: {
    title: "Contact",
    main: "Demande d’informations",
    description: {
      address: "5820 Allentown Way",
      location: "Temple Hills MD 20748",
      phone: "+1 202-439-2950",
      email: "laure.caye@orange.fr",
    },
  },
  processes: [
    {
      color: "brown",
      itemNumber: "01",
      title: "Planning & Conceptualizing",
      description: "Get your head in the clouds. This phase is all about understanding your wants and needs for the space. Typically, this takes 2 to 3 sessions to get to a stage that everyone is comfortable with and has design clarity. This will be the start of the framework that creates the end result so we take this phase seriously. We’ll get on the same page regarding budget and design parameters and then we will start to put together imagery to provide a sense of the design intent. In short, we will do what it takes to make sure we understand exactly what the clients’ intentions are for their home. This is where the dream begins.",
      image: "../../../assets/images/img-1.jpg",
    },
    {
      color: "blue",
      itemNumber: "02",
      title: "Design & Development",
      description: "This is when the clay starts to take shape. At this stage, we start to pull and plan actual materials and present them to the client. Room and furniture layouts, cabinetry, appliances and plumbing are all reviewed at this stage to ensure they are meeting your functional needs. Because good design is functional design.",
      image: "../assets/images/img-2.jpg",
    },
    {
      color: "green",
      itemNumber: "03",
      title: "Construction drawing & documentation",
      description: "The pieces are falling into place now. At this stage, the ideas have been approved and evaluated and we begin with drawings. Areas we’ve specified with materials will be drawn out for design intent and approval and we will use the architect’s drawings to create the plans needed. Each drawing will follow an approval process with the client to allow for changes and revisions. All approved materials (stones, woods, tiles, appliances, plumbing, etc.) will be specified and given to your builder for pricing.",
      image: "../assets/images/img-3.jpg",
    },
    {
      color: "brown",
      itemNumber: "04",
      title: "Coordination & supervision",
      description: "Documents are being submitted and the ball is rolling. We want this process to feel stress-free and fun for you, so we carefully monitor everything from beginning to end. We’ll make ourselves available for meetings, coordinate monthly status reports, and track every order so you’re never left in the dark.",
      image: "../assets/images/img-4.jpg",
    },
    {
      color: "blue",
      itemNumber: "05",
      title: "Furniture, Fabric & Lighting Selections",
      description: "The final step of the process is selecting furniture, fabric and lighting. These finishes are the biggest touch points of a home. They’re what you’ll interact with on a day-to-day basis and bring an interior to life. So we’ll visit showrooms, interact with textures, and scrupulously analyze light fixtures until everything feels just right.",
      image: "../assets/images/img-5.jpg",
    },
  ]
}
