import * as React from "react";
import Img1 from "../../../assets/images/img-1.jpg";
import Img2 from "../../../assets/images/img-2.jpg";
import Img3 from "../../../assets/images/img-3.jpg";
import Img4 from "../../../assets/images/img-4.jpg";
import Img5 from "../../../assets/images/img-5.jpg";
import content from "../../../content/about"

import "./process.css";

class Process extends React.Component {
  constructor() {
    super();
    this.state = {
      tile2OnScreen: false,
      tile3OnScreen: false,
      tile4OnScreen: false,
      tile5OnScreen: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", () => {
      const distanceFromTop = window.pageYOffset;
      let distTile2,distTile3,distTile4,distTile5;
      if(document.querySelector("#tile2")!==null){
         distTile2 =
        window.pageYOffset +
        document.querySelector("#tile2").getBoundingClientRect().top -
        700;
      }
      if(document.querySelector("#tile3")!==null){
       distTile3 =
        window.pageYOffset +
        document.querySelector("#tile3").getBoundingClientRect().top -
        500;
      }
      if(document.querySelector("#tile4")!==null){
       distTile4 =
        window.pageYOffset +
        document.querySelector("#tile4").getBoundingClientRect().top -
        500;
      }
      if(document.querySelector("#tile5")!==null){
       distTile5 =
        window.pageYOffset +
        document.querySelector("#tile5").getBoundingClientRect().top -
        500;
      }

      if (distTile2 < distanceFromTop) {
        if (!this.state.elementOnViewport) {
          this.setState({ tile2OnScreen: true });
        }
      }

      if (distTile3 < distanceFromTop) {
        if (!this.state.elementOnViewport) {
          this.setState({ tile3OnScreen: true });
        }
      }

      if (distTile4 < distanceFromTop) {
        if (!this.state.elementOnViewport) {
          this.setState({ tile4OnScreen: true });
        }
      }

      if (distTile5 < distanceFromTop) {
        if (!this.state.elementOnViewport) {
          this.setState({ tile5OnScreen: true });
        }
      }
    });
  }


  render() {
    return (
      <section className="container">
        {content.processes.map((process, index) => (
          <div className={`container__item ${process.color}`} id={`title${index+1}`}>
            <div className="container__title grid">
                {/* <div className="container__item-number textanim"
                     style={{ animationDelay: "0.2s",
                              display: "inline-block",
                              // flex: "1",
                              border: "2px solid yellow"  }}
                              >
                                {`${process.itemNumber}`}
                </div> */}
                <h2 className="container__item-title border-bottom textanim"
                    style={{ animationDelay: "0.3s",
                             color: "black", 
                             textAlign: "left" }}
                             >
                              {`Step ${index+1}`}
                              {/* {`${process.itemNumber}`} {`${process.title}`} */}
                </h2>
              </div>

              <div className="container__item-content grid">
                <div className="container__item-text textanim"
                    style={{ animationDelay: "0.4s" }}
                    >
                      {`${process.description}`}
                </div>
              </div>
          </div>
        )
        )}
      </section>
    );
  }
}

export default Process;
