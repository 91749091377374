// extracted by mini-css-extract-plugin
export var aboutContainer = "about-module--aboutContainer--2b816";
export var awsBtn = "about-module--aws-btn--4fc76";
export var column = "about-module--column--40dc9";
export var contactContainer = "about-module--contactContainer--1d2a5";
export var description = "about-module--description--aa4e7";
export var displayFlex = "about-module--displayFlex--efdbd";
export var fifthPart = "about-module--fifthPart--36d25";
export var fifthPartDescription = "about-module--fifthPartDescription--6490d";
export var fifthPartMain = "about-module--fifthPartMain--eac7d";
export var firstImgHeight = "about-module--firstImgHeight--2add6";
export var fourthContainer = "about-module--fourthContainer--654ce";
export var fourthHeader = "about-module--fourthHeader--621fb";
export var fourthPart = "about-module--fourthPart--c59c4";
export var fourthPartImages = "about-module--fourthPartImages--050d8";
export var fourthPartMain = "about-module--fourthPartMain--f4ae8";
export var gmap_canvas = "about-module--gmap_canvas--71287";
export var left = "about-module--left--08013";
export var main = "about-module--main--db41a";
export var mapouter = "about-module--mapouter--c3d7b";
export var part1 = "about-module--part1--68c55";
export var part1Description = "about-module--part1Description--ffede";
export var part1Header = "about-module--part1Header--06b82";
export var part1ImgHolder = "about-module--part1ImgHolder--32daa";
export var part1Main = "about-module--part1Main--4f929";
export var right = "about-module--right--09abb";
export var row = "about-module--row--bbe24";
export var sample = "about-module--sample--1de69";
export var secondPart = "about-module--secondPart--4700e";
export var secondPartBox = "about-module--secondPartBox--393b6";
export var secondPartBoxDescription = "about-module--secondPartBoxDescription--c0edd";
export var secondPartBoxMain = "about-module--secondPartBoxMain--ea5e5";
export var secondPartBoxTitle = "about-module--secondPartBoxTitle--b915d";
export var secondPartImage = "about-module--secondPartImage--8ed91";
export var secondPartImgContainer = "about-module--secondPartImgContainer--745bb";
export var submain2 = "about-module--submain2--ab0ca";
export var testimonial = "about-module--testimonial--84a24";
export var thirdPart = "about-module--thirdPart--238be";
export var thirdPartDescription = "about-module--thirdPartDescription--10b5d";
export var thirdPartMain = "about-module--thirdPartMain--d5f59";
export var thirdPartTitle = "about-module--thirdPartTitle--b8cab";
export var title = "about-module--title--57107";