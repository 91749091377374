
module.exports = {
    description:
      "Kitchen and Bathroom remodeling is our specialty. Since 2001 our philosophy has been to understand and deliver on the particular wants and needs of every client.",
    reviews: [
      {
        testimonial: '" I would like to say my experience with Metro Flooring was: Seamless, by that I mean Susan was very professional, listen to my every need and concern her staff was professional also. I had not a worry I could leave her crew in my home and run errands, did not worry that anything would be taken or broken...."',
        name: "Alicia Perks",
        type: "Reconstruction",
        stars: "⭐⭐⭐⭐",
        icon: "4.svg",
      },
      {
        testimonial: '" I found working with Metro Flooring and especially Susan Yirga an enjoyable experience. Knowing that I was unable to even pick out paint color, I depended on Susan to bring my dream of a new kitchen to life. I would recommend them to anyone who needs remodeling. Fast and reliable"',
        name: "Mark Braie",
        type: "Interior Finishes",
        stars: "⭐⭐⭐⭐⭐",
        icon: "4.5.svg",
      },
      // {
      //   testimonial: "I scheduled an appointment with Metro flooring after getting 2 other estimates and had the pleasure to consult with Ms Sosena. Frankly, I decided to go with Metro because she was very professional, polite and she made me quite at ease ! They did an amazing job. The floors are impeccable, and things were done right on the time schedule that was allowed. Two of my friends were waiting in line to get the new sleek look! They did an amazing job! Put your trust in this company and you will feel like you are living in a brand new house!",
      // },
      // {
      //   testimonial: "Metro Flooring and Interior Design did a wonderful job upgrading our new kitchen. They helped us with the flooring selection from showroom samples and made a contemporary interior design plan that turned out great. The staff did a great job from start to finish, getting our project on time and under budget. I would definitely recommend them to my friends and neighbors.",
      // },
      {
        testimonial: '" I would like to say my experience with Metro Flooring was: Seamless, by that I mean Susan was very professional, listen to my every need and concern her staff was professional also. I had not a worry I could leave her crew in my home and run errands, did not worry that anything would be taken or broken...."',
        name: "Tommy Baile",
        type: "Kitchen Remodel",
        stars: "⭐⭐⭐",
        icon: "5.svg",
      },
      {
        testimonial: '" I found working with Metro Flooring and especially Susan Yirga an enjoyable experience. Knowing that I was unable to even pick out paint color, I depended on Susan to bring my dream of a new kitchen to life. I would recommend them to anyone who needs remodeling. Fast and reliable "',
        name: "Venesa Hall",
        type: "Bathroom Remodel",
        stars: "⭐⭐⭐⭐⭐",
        icon: "5.svg",
      },
    ],
  }
  