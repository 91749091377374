import React from "react"

import Layout from "../components/Layout"
import AboutPage from "../components/AboutPage/about"


export default function About() {
  return (
    <section>
      <AboutPage />
    </section>
  )
}
